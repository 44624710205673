.app-container-privacy {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 3.7vw;
}

.app-container-privacy .bg-img {
  position: absolute;
  top: -3.7vw;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  user-select: none;
}

.app-container-privacy .content {
  z-index: 1;
  color: white;
  min-height: 44vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-container-privacy .content .privacy-img {
  margin-top: 3vw;
  width: 28.3vw;
  user-select: none;
}

.app-container-privacy .content .black-box {
  background-color: #0d0d0d;
  width: 61vw;
  margin-top: 4.7vw;
  padding: 3.9vw 5.2vw;
}

.app-container-privacy .content .black-box .privacy-image {
  width: 100%;
  user-select: none;
}