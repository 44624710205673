.app-container-home {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 3.7vw;
}

.app-container-home .bg-img {
  position: absolute;
  top: -3.7vw;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  user-select: none;
}

.app-container-home .right-img {
  position: absolute;
  top: -3.7vw;
  right: 0;
  width: 62.2vw;
  user-select: none;
}

.app-container-home .content {
  z-index: 1;
  color: white;
  min-height: 44vw;
}

.app-container-home .content .social-img {
  margin-left: 19.6vw;
  margin-top: 3.1vw;
  width: 30.3vw;
  user-select: none;
}