.app-footer {
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: white;
  z-index: 10;
  height: 7.7vw;
  line-height: 1;
  border-top: 1px solid rgba(255, 255, 255, 0.03);
}

.app-footer .footer-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.app-footer .footer-nav .copyright {
  margin-left: 19.6vw;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1vw;
  user-select: none;
}

.app-footer .footer-nav .nav-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  margin-right: 19.6vw;
  user-select: none;
}

.app-footer .footer-nav .nav-ul .nav-li {
  font-size: 1vw;
  cursor: pointer;
  padding: 1vw 0 1vw 2.6vw;
}

.app-footer .footer-nav .nav-ul .nav-li::before {
  display: block;
  content: attr(title);
  font-family: 'RanyBold';
  visibility: hidden;
  height: 0;
  color: transparent;
  overflow: hidden;
}

.app-footer .footer-nav .nav-ul .nav-li .nav-title {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'RanyBold';
}

.app-footer .footer-nav .nav-ul .nav-li:hover .nav-title {
  color: rgba(255, 255, 255, 1);
  font-family: 'RanyBold';
}

.app-footer .footer-nav .nav-ul .nav-li-selected .nav-title {
  color: rgba(255, 255, 255, 1);
  font-family: 'RanyBold';
}