.error-container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-container .title {
  color: white;
}

.error-container .go-home {
  color: #42b883;
}