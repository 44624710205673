.app-header {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.1vw;
  z-index: 10;
  padding: 0;
  color: white;
  line-height: 1;
  margin-top: 1.6vw;
}

.app-header .top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header .top-nav .header-logo {
  width: 11.8vw;
  margin-left: 19.6vw;
  user-select: none;
}

.app-header .top-nav .nav-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  margin-right: 19.6vw;
  user-select: none;
}

.app-header .top-nav .nav-ul .nav-li {
  font-size: 1vw;
  cursor: pointer;
  padding: 1vw 0 1vw 2.6vw;
}

.app-header .top-nav .nav-ul .nav-li::before {
  display: block;
  content: attr(title);
  font-family: 'RanyBold';
  visibility: hidden;
  height: 0;
  color: transparent;
  overflow: hidden;
}

.app-header .top-nav .nav-ul .nav-li .nav-title {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'RanyBold';
}

.app-header .top-nav .nav-ul .nav-li:hover .nav-title {
  color: rgba(255, 255, 255, 1);
  font-family: 'RanyBold';
}

.app-header .top-nav .nav-ul .nav-li-selected .nav-title {
  color: rgba(255, 255, 255, 1);
  font-family: 'RanyBold';
}